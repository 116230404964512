import React from "react";
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const AlertBar = ({open, severity, message, setOpened}) => {
  return(
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpened(false)}>
          <Alert onClose={() => setOpened(false)} severity={severity} sx={{width: '100%'}}>
              {message !== '' ? message : 'Si è verificato un errore, si prega di riprovare'}
          </Alert>
      </Snackbar>
  )
}
