import {saraTheme} from "../../theme/saraTheme";

const yup = require("yup");
export const logisticsMap = [
    {
        id: 'partecipazioneBari',
        name: "partecipazioneBari",
        title: 'Evento Bari',
        subtitle: "",
        fields: [
            {
                id: 'agendaBari',
                name: "agendaBari",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'bari',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'parcheggioBari',
                name: "parcheggioBari",
                type: 'parcheggio',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'bari',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'bari',
                    value: 'si'
                }
            ]
        },
    },
    {
        id: 'partecipazioneRoma',
        name: "partecipazioneRoma",
        title: 'Evento Roma',
        subtitle: "",
        fields: [
            {
                id: 'agendaRoma',
                name: "agendaRoma",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'roma',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'parcheggioRoma',
                name: "parcheggioRoma",
                type: 'parcheggio',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'roma',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'roma',
                    value: 'si'
                }
            ]
        },
    },
    {
        id: 'partecipazioneMilano',
        name: "partecipazioneMilano",
        title: 'Evento Milano',
        subtitle: "",
        fields: [
            {
                id: 'agendaMilano',
                name: "agendaMilano",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'milano',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'parcheggioMilano',
                name: "parcheggioMilano",
                type: 'parcheggio',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'milano',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'milano',
                    value: 'si'
                }
            ]
        },
    },
]
