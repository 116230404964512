import React from "react";
import {Main} from "../layout/Main";
import {Box, Container, Stack, Typography} from "@mui/material";
import {saraTheme} from "../theme/saraTheme";
import {useTranslation} from "react-i18next";

const ClosedRegistrations = () => {
    const {t} = useTranslation()
    return (
        <Main>
            <Container maxWidth={'md'} sx={{py: 2}}>
                <Typography textAlign={'center'} variant={'h4'} fontWeight={'bold'}>Le iscrizioni sono chiuse!</Typography>
                <Stack my={5}>
                    <Box mb={5} sx={{
                        width: '100%',
                        height: '0.05rem',
                        background: saraTheme.palette.secondary.main
                    }}/>
                    <Typography>
                        {t('contattare', {contattare: 'contatti'})}
                        <a style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                           href={"mailto:segreteria@roadshowsara2024.it"}
                           target={"_blank"}>segreteria@roadshowsara2024.it</a> / <br/>tel. 06 6220 6866.
                    </Typography>
                </Stack>
            </Container>
        </Main>
    )
}

export default ClosedRegistrations