import React from "react";
import {Box, Stack} from "@mui/material";

export const Main = ({children}) => {
    return (
        <Stack sx={{minHeight:'100vh'}} justifyContent={'space-between'}>
            <Box>
                <img src={require('../assets/images/header.jpg')} style={{width:'100%'}}/>
            </Box>
            {children}
            <Box>
                <img src={require('../assets/images/footer.jpg')} style={{width:'100%', marginBottom:'-0.6rem'}}/>
            </Box>
        </Stack>
    )
}
