import {Grid, Stack, Typography} from "@mui/material";
import React from "react";

const CustomAgenda = ({agendaId}) => {
    const agenda = agendas[agendaId]
    return (agenda && <Stack mt={2}>
            <Typography component='div'>
                {agenda.sede}
            </Typography>
            <Stack mt={1} direction={"row"} spacing={0.5}>
                <Typography color={'primary'} fontWeight={'bold'}>{agenda.title}</Typography>
                <Typography color={'primary'} fontWeight={'bold'}>AGENDA</Typography>
            </Stack>
            {agenda.data.map((data, i) => (
                <Grid key={i} mt={1} container>
                    <Grid item xs={6} sm={2}>
                        <Typography fontWeight={'bold'}>
                            {data.time}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={10}>
                        {data.event}
                    </Grid>
                </Grid>

            ))}
            {/*<br/>
            <Typography>
                <u>
                    * Gli orari indicati potrebbero subire variazioni. L'agenda definitiva le sarà inviata via email in prossimità dell'evento.
                </u>
            </Typography>*/}
        </Stack>
    );
}

export default CustomAgenda

const agendas = {
    agendaBari: {
        title: "03 OTTOBRE · ",
        sede: <>Sede dell'evento: <b>The Nicolaus Hotel</b> (Via Cardinale Agostino Ciasca, 27, 70124 Bari BA)</>,
        data: [
            {time: "ore 12:00", event: "Accredito"},
            {time: "ore 12:30", event: "Light Lunch"},
            {time: "ore 14:00", event: "Apertura Lavori"},
            {time: "ore 17:15", event: "Chiusura Lavori"},
        ]
    },
    agendaRoma: {
        title: "04 OTTOBRE · ",
        sede: <>Sede dell'evento: <b>Rome Sheraton Parco de' Medici</b> presso <b>Building 1</b> (Via Salvatore Rebecchini, 39, 00148 Roma RM)</>,
        data: [
            {time: "ore 09:00", event: "Welcome e accredito"},
            {time: "ore 10:00", event: "Apertura Lavori"},
            {time: "ore 13:15", event: "Chiusura Lavori"},
            {time: "ore 13:15 - 14:30", event: "Light lunch"},
        ]
    },

    agendaMilano: {
        title: "09 OTTOBRE · ",
        sede: <>Sede dell'evento: <b>Sheraton Milan San Siro</b> (Via Caldera, 3, 20153 Milano MI)</>,
        data: [
            {time: "ore 12:00", event: "Accredito"},
            {time: "ore 12:30", event: "Light Lunch"},
            {time: "ore 14:00", event: "Apertura Lavori"},
            {time: "ore 17:15", event: "Chiusura Lavori"},
        ]
    },



    agendaKickOff15: {
        title: "15 FEBBRAIO MATTINA · ",
        data: [
            {time: "08:45 - 9:30", event: "Accredito e Welcome coffee"},
            {time: "9:30 - 12:00", event: "Sessione di lavoro Direzione Sinistri"},
            {time: "09:00 - 12:00", event: "Sessione di lavoro Direzione Commerciale"},
            {time: "12:30 - 13:30", event: "Light lunch"},
            {time: "13:30", event: "Trasferimento per Convention"},
        ]
    },
    agendaConventionSara: {
        title: "15 FEBBRAIO POMERIGGIO · ",
        data: [
            {time: "13:50 - 14:30", event: "Accredito"},
            {time: "14:45 - 18:15", event: "Lavori in plenaria"},
            {time: "18:15 - 19:15", event: "Aperitivo"},
            {time: "19:15 - 20:15", event: "Intrattenimento"},
            {time: "20:30 - 23:00", event: "Cena"},
            {time: "23:00", event: "Rientro"},
        ]
    },
    agendaMeetingCommerciale: {
        title: "16 FEBBRAIO MATTINA · ",
        data: [
            {time: "09:00 - 09:30", event: "Accredito e welcome coffee"},
            {time: "09:30 - 13:00", event: "Sessione di lavoro"},
            {time: "13:00 - 14:00", event: "Light Lunch"},
        ]
    },

}