import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Error} from "./pages/Error";
import ClosedRegistrations from "./pages/ClosedRegistrations";
import {Logistics} from "./pages/Logistics";

function App() {
  return (
    <div>
      <CssBaseline/>
      <Routes>
        <Route path={'/'} element={<ClosedRegistrations/>}/>
        <Route path={'/error'} element={<Error/>}/>
        <Route path={'/register/:id'} element={<ClosedRegistrations/>}/>
        <Route path={'/logistics/:id'} element={<Logistics/>}/>
      </Routes>
    </div>
  );
}

export default App;
