import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {createValidationSchema} from "../functions/createValidationScheme";
import {registrationMap} from "../assets/data/registrationMap";
import {FormContent, Title} from "../components/Register/FormContent";
import {useNavigate, useParams} from "react-router-dom";
import {Formik} from "formik";
import axios from "axios";
import {API_URL} from "../config";
import {AlertBar} from "../components/shared/AlertBar";
import {Main} from "../layout/Main";
import useAxios from "axios-hooks";
import {filter} from "lodash";
import {saraTheme} from "../theme/saraTheme";
import {useTranslation} from "react-i18next";
import {logisticsMap} from "../assets/data/logisticsMap";

const _data = {
    nome: "Matilde",
    cognome: "Ugolini",
    email: "matilde@plesh.co",
    categoria: "AGENTE",
    codiceAgenzia: "asd",

    bari: "si",
    roma: "si",
    milano: "si",
}

export const Logistics = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const [loading, setLoading] = React.useState(false)
    const [sending, setSending] = React.useState(false)

    /** MOCK DATA DA TOGLIERE */
    const [initialValues, setInitialValues] = React.useState({})

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [{data, loading: dataLoading, error: dataError}] = useAxios(`${API_URL}profile/${id}`, {manual: false})
    const [categoria, setCategoria] = useState("")
    const [partecipazione, setPartecipazione] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (dataError) {
            navigate('/error')
        }
        if (data && !dataLoading && !dataError) {
            setCategoria(data.categoria)
            getData()
        }
    }, [data, dataLoading, dataError])

    const getData = async () => {
        setLoading(true)
        let d = {
            categoria: data.categoria,

            bari: data.bari,
            roma: data.roma,
            milano: data.milano,
        }
        logisticsMap.map(s => {
            return filter(s.fields, (f => (f.type !== 'note' && f.type !== 'agenda'))).forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = data[key] ?? null
                    } else if (f.type === 'checkbox') {
                        d[key] = data[key] ?? false
                    } else {
                        d[key] = data[key] ?? ''
                    }
                }
            )
        })
        setInitialValues(d)
        setLoading(false)
    }

    return (
        <Main>
            <AlertBar open={error} setOpened={setError} severity={'error'} message={errorMessage}/>
            <Container maxWidth={'md'} sx={{py: 2}}>
                {
                    (dataLoading || Object.keys(initialValues).length <= 0) ?
                            <Box sx={{textAlign: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Stack>
                                <Formik
                                    initialValues={{...initialValues}}
                                    onSubmit={values => {
                                        //sendData(values)
                                    }}
                                >
                                    {(formik) =>
                                        <form onSubmit={formik.handleSubmit}>
                                            <FormContent formik={formik} formMap={logisticsMap}
                                                         categoria={categoria}/>
                                        </form>
                                    }
                                </Formik>
                                <Stack mb={4}>
                                    <Title title={'ACCREDITO'}/>
                                    <Typography>
                                        Le ricordiamo di portare con sé il <b>QR CODE</b>, ricevuto già in fase di registrazione, da presentare all’ingresso in formato digitale o cartaceo.
                                    </Typography>
                                    <img src={`https://storage.googleapis.com/sara-roadshow-24/${id}.png`}
                                         width={'170px'}
                                         style={{margin: 'auto'}}
                                    />
                                </Stack>
                                <Stack>
                                    <Box sx={{
                                        width: '100%',
                                        height: '0.07rem',
                                        background: saraTheme.palette.secondary.main
                                    }}/>
                                    <Title title={'DRESS CODE'}/>
                                    <Typography>
                                        È gradito un abbigliamento business.
                                    </Typography>
                                </Stack>
                                <Stack my={5}>
                                    <Box mb={2} sx={{
                                        width: '100%',
                                        height: '0.07rem',
                                        background: saraTheme.palette.secondary.main
                                    }}/>
                                    <Typography>
                                        {t('contattare', {contattare: ["DIRETTORE", "PRESIDENTE"].includes(categoria) ? 'contatti' : 'contatta'})}
                                        <a style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                                           href={"mailto:segreteria@roadshowsara2024.it"}
                                           target={"_blank"}>segreteria@roadshowsara2024.it</a> / <br/>tel. 06 6220 6866.
                                    </Typography>
                                </Stack>
                            </Stack>
                }
            </Container>
        </Main>
    )
}
