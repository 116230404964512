import {createTheme, responsiveFontSizes} from "@mui/material";

export const saraTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: "#00205c",
        },
        secondary: {
            main: "#f68d34",
        }
    }
}))
