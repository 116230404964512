import React from "react";
import {Box, Typography} from "@mui/material";
import {Main} from "../layout/Main";
import {red} from "@mui/material/colors";
import {saraTheme} from "../theme/saraTheme";

export const Error = () => {
    return (
        <Main>
            <Box>
                <Typography textAlign={'center'} color={red[400]} variant={'h5'} fontWeight={'bold'}>
                    Il link utilizzato non è valido
                </Typography>
                <Typography textAlign={'center'} variant={'h6'} fontWeight={'bold'}>
                    Contatta la segreteria organizzativa a:
                    <br/>
                    <a style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                       href={'mailto:segreteria@roadshowsara2024.it'} target={'_blank'}>segreteria@roadshowsara2024.it</a>
                </Typography>
            </Box>
        </Main>
    )
}