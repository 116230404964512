import {Box, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import {saraTheme} from "../theme/saraTheme";
import {Title} from "./Register/FormContent";

const CustomParcheggio = ({parkingId}) => {
    const parcheggio = parkings[parkingId]
    return (parcheggio && <Stack>
            {/*<Box sx={{width: '100%', height: '0.07rem', background: saraTheme.palette.secondary.main}}/>*/}
            <Title title={`PARCHEGGIO ${parcheggio.title}`} />
            <Typography>
                {parcheggio.description}
            </Typography>
            <Box mt={4} sx={{width: '100%', height: '0.07rem', background: saraTheme.palette.secondary.main}}/>
        </Stack>
    );
}

export default CustomParcheggio

const parkings = {
    parcheggioBari: {
        title: "The Nicolaus Hotel",
        sede: <>Sede dell'evento: <b>The Nicolaus Hotel</b> (Via Cardinale Agostino Ciasca, 27, 70124 Bari BA)</>,
        description: <>
            Garage interno a pagamento con circa 70 posti auto: l'ingresso al garage è gestito da sbarra automatica e rilascio di ticket sino ad esaurimento posti. I posti non sono prenotabili.
            <br/><br/>
            Tariffa: 2 € l’ora.
        </>,
    },
    parcheggioRoma: {
        title: "Rome Sheraton Parco de' Medici",
        description: <>
            L'hotel dispone di un parcheggio (B1) all'esterno dell'edificio, incustodito e fino ad esaurimento posti (max 200).
            <br/><br/>
            Costo: 10€
            <br/><br/>
            A pochi passi dall'ingresso del building n. 1 (Viale Salvatore Rebecchini, 39) si trova il parcheggio del cinema The Space, gratuito ed incustodito.
        </>,
    },

    parcheggioMilano: {
        title: "Sheraton Milan San Siro",
        description: <>
            In hotel è presente un parcheggio esterno e/o sotterraneo fino a 180 posti non prenotabili, a pagamento.
            <br/><br/>
            Costo: €10 per 12 ore / €20 per 24 ore
            <br/><br/>
            Segnaliamo, inoltre, che vi è un:
            <ul>
                <li>Parcheggio gratuito in via Caldera 21, a 5 minuti di distanza a piedi (parcheggio CBP gratuito 24/h.</li>
                <li>Parcheggio gratuito in via Novara (prima dell’incrocio con via Caldera)  2 minuti distanza a piedi.</li>
                <li>Parcheggio custodito e coperto Onepark, zona stadio, da verificare la disponibilità sul sito.</li>
            </ul>
        </>,
    },
}