import {saraTheme} from "../../theme/saraTheme";

const yup = require("yup");
export const registrationMap = [
    {
        id: 'registrazioneUtente',
        name: "registrazioneUtente",
        title: 'registrazioneTitolo',
        subtitle: "",
        fields: [
            {
                id: 'nome',
                name: 'nome',
                label: 'nome',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: true,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'cognome',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: true,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            /*{
                id: 'tappa',
                name: 'tappa',
                label: 'Tappa',
                type: 'text',
                required: false,
                validations: ['string','required'],
                precompiled: true,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },*/
            {
                id: 'email',
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
                validations: ['string','required'],
                precompiled: true,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'codiceAgenzia',
                name: 'codiceAgenzia',
                label: 'Codice Agenzia',
                type: 'text',
                required: false,
                validations: ['string','when'],
                precompiled: true,
                params: {
                    when: [
                        ['categoria'], {
                            is: (categoria) => categoria === 'AGENTE',
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'categoria',
                            value: 'AGENTE'
                        }
                    ]
                }
            },
            {
                id: 'cellulare',
                name: 'cellulare',
                label: 'Telefono Aziendale',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'intolleranze',
                name: 'intolleranze',
                label: 'intolleranze',
                type: 'textBlock',
                required: false,
                validations: [],
                precompiled: false,
                cols:{
                    xs:12,
                }
            },
            {
                id: 'intolleranzeNote',
                name: 'intolleranzeNote',
                label: 'intolleranzeNote',
                type: 'note',
                required: false,
                validations: [],
                precompiled: false,
                cols:{
                    xs:12,
                }
            },
        ]
    },
    {
        id: 'partecipazioneBari',
        name: "partecipazioneBari",
        title: 'Evento Bari',
        subtitle: "",
        fields: [
            {
                id: 'agendaBari',
                name: "agendaBari",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'bari',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'bariPartecipazioneConferma',
                name: 'bariPartecipazioneConferma',
                label: "confermaPartecipazione",
                type: 'radiogroup',
                validations: ["string", 'when'],
                required: false,
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    when: [
                        ['bari'], {
                            is: (bari) => bari === 'si',
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'bari',
                            value: 'si'
                        }
                    ]
                },
            },
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'bari',
                    value: 'si'
                }
            ]
        },
    },
    {
        id: 'partecipazioneRoma',
        name: "partecipazioneRoma",
        title: 'Evento Roma',
        subtitle: "",
        fields: [
            {
                id: 'agendaRoma',
                name: "agendaRoma",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'roma',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'romaPartecipazioneConferma',
                name: 'romaPartecipazioneConferma',
                label: "confermaPartecipazione",
                type: 'radiogroup',
                validations: ["string", 'when'],
                required: false,
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    when: [
                        ['roma'], {
                            is: (roma) => roma === 'si',
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'roma',
                            value: 'si'
                        }
                    ]
                },
            }
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'roma',
                    value: 'si'
                }
            ]
        },
    },
    {
        id: 'partecipazioneMilano',
        name: "partecipazioneMilano",
        title: 'Evento Milano',
        subtitle: "",
        fields: [
            {
                id: 'agendaMilano',
                name: "agendaMilano",
                type: 'agenda',
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'milano',
                            value: 'si'
                        }
                    ]
                },
                cols:{
                    xs:12,
                    md:12
                }
            },
            {
                id: 'milanoPartecipazioneConferma',
                name: 'milanoPartecipazioneConferma',
                label: "confermaPartecipazione",
                type: 'radiogroup',
                validations: ["string", 'when'],
                required: false,
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    when: [
                        ['milano'], {
                            is: (milano) => milano === 'si',
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: 'milano',
                            value: 'si'
                        }
                    ]
                },
            }
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: 'milano',
                    value: 'si'
                }
            ]
        },
    },
    {
        id: 'privacy',
        name: "privacy",
        title: 'privacy',
        subtitle: "",
        fields: [
            {
                id: 'privacy',
                name: 'privacy',
                label: <span>Dichiaro di aver preso visione della <a
                    style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                    href={'/Informativa privacy SITO - Roadshow 2024 Sara Assicurazioni.pdf'} target={'_blank'}>Privacy Policy</a></span>,
                type: 'checkbox',
                validations: ['bool', "required", "oneOf"],
                required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            },
            {
                id: 'privacyImmagine',
                name: 'privacyImmagine',
                label: <span>Acconsento al trattamento della mia immagine secondo quanto disciplinato all'interno della <a
                    style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                    href={'/Informativa privacy SITO - Roadshow 2024 Sara Assicurazioni.pdf'} target={'_blank'}>Privacy Policy</a></span>,
                type: 'checkbox',
                validations: ['bool', "nullable"],
                //required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                }
            }
        ]
    },
]
